import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import WebriQForm from "@webriq/gatsby-webriq-form"
import BookCover from "../images/new-images/white-paper-banner.jpg"
import ConsultingGroupPrice from "../images/new-images/white-paper-hero-background-image.jpg"

const Banner = () => (
  <>
    <div
      className="hero-banner white-paper"
      style={{
        backgroundColor: "#00b5f4",
        backgroundImage: `url(${ConsultingGroupPrice})`,
        backgroundPosition: "center",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-8 mr-auto">
            <div className="hero-custom-content">
              <h5 className="text-light-blue text-uppercase font-weight-bold mt-0 mb-4">
                Chaotically Busy to Productive Bliss
              </h5>
              <h1 className="page-title font-weight-medium mt-0 mb-4">
                Get an Insane ROI with the Right Integrations and Automation
              </h1>
              <p>
                Discover 5 actionable ways to cut costs, streamline systems, and
                the results you can expect.
              </p>
              <a className="font-weight-medium" href="#free-guide-form">
                Read the Guide
                <i className="ml-2 item-arrow" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
)

class QuandaryWhitepaper extends React.Component {
  render() {
    return (
      <Layout banner={<Banner />} location={this.props.location}>
        <Seo
          title="Quandary Build (Software Dev.) | Quandary Consulting Group"
          description="Bespoke software development consulting and solutions. Get custom applications for your growing business without the custom build costs. Price-lock Guarantee."
        />
        <div id="quotes" className="white-paper">
          <div className="container">
            <div className="row py-5">
              <div className="col-md-5 text-center text-lg-right">
                <img className="img-fluid" alt="Book Cover" src={BookCover} />
              </div>
              <div className="col-md-7 align-self-center px-5 pt-5">
                <h2>In this white paper, you’ll learn: </h2>
                <ol className="list-unstyled white-paper-lists pt-3">
                  <li className="mb-4">
                    <span className="steps">1</span> Real ways you can cut costs
                    in your business
                  </li>
                  <li className="mb-4">
                    <span className="steps">2</span> How to streamline your
                    business processes
                  </li>
                  <li id="free-guide-form" className="mb-4">
                    <span className="steps">3</span> The mistakes you need to
                    avoid if you want to work <br /> less and accomplish more
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="container text-center">
          <div className="row py-5">
            <div className="col-sm-8 m-auto">
              <h2>It’s time to steal back time from your business.</h2>
              <p>Ready to stop working countless nights and weekends?</p>
              <WebriQForm
                id="contact-form"
                name="White Paper Form"
                className="contactForm custom-form"
                formId="1ebb4de9-3353-4105-a472-39ef954ed0c0"
                redirectUrl="/process-improvement-statistics/"
              >
                <div className="row">
                  <div className="col-md-6 my-2">
                    <input
                      type="text"
                      name="full-name"
                      size={40}
                      className="form-control"
                      placeholder="Full Name"
                      required
                    />
                  </div>
                  <div className="col-md-6 my-2">
                    <input
                      type="email"
                      name="your-email"
                      size={40}
                      className="form-control"
                      placeholder="Email"
                      required
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 my-2">
                    <div className="form-group text-left">
                      <div className="webriq-recaptcha" />
                      <div className="input-filled mt-2">
                        <button
                          type="submit"
                          className="btn btn-primary btn-arrow"
                        >
                          Read the White Paper
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </WebriQForm>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default QuandaryWhitepaper
